@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #141416;
}
.grey {
  display: block;
  z-index: 5;
  position: fixed;
  background-color: #43434399;
  width: 100%;
  height: 100vh;
  top: 0;
}
.connect {
  display: block;
  z-index: 10;
  position: fixed;
  top: 20%;
}
