.rainbow-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-family: sans-serif;
  font-weight: bold;
  border-radius: 30px;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
  transition: color 0.3s ease;
}

.rainbow-button:hover {
  /* color: #000; */
  animation: animate 8s linear infinite;
}

@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}
.rainbow-button:before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  z-index: -1;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 400%;
  border-radius: 40px;
  opacity: 0;
  transition: 0.5s;
}

.rainbow-button:hover:before {
  filter: blur(20px);
  opacity: 1;
  animation: animate 8s linear infinite;
}
